<template>
    <div>
        <div class="demo-spin-article">
            <div class="col-md-12 mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('monthlyOvertime.employeeName')
                }}</label>
                <div>
                    <Select
                        v-model="model.employee_id"
                        filterable
                        :remote-method="loadEmployee"
                        :clearable="true"
                        :class="{
                            'is-invalid': errors.has('employee_id')
                        }"
                    >
                        <Option
                            v-for="(employeeProfile, index) in employeeProfiles"
                            :value="employeeProfile.employee_id"
                            :key="index"
                            :label="employeeProfile.employee_name_en"
                            >{{ employeeProfile.employee_name_en }}|{{
                                employeeProfile.employee_name_kh
                            }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_id')"
                    >
                        {{ errors.first('employee_id') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.timeFrom')
                    }}</label>
                    <div>
                        <time-picker
                            v-model="model.time_from"
                            type="time"
                            placeholder="HH:mm"
                            style="width:370px"
                            format="HH:mm"
                            confirm
                            :class="{
                                'is-invalid': errors.has('time_from')
                            }"
                        >
                        </time-picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('time_from')"
                        >
                            {{ errors.first('time_from') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.timeTo')
                    }}</label>
                    <div>
                        <time-picker
                            v-model="model.time_to"
                            type="time"
                            placeholder="HH:mm"
                            style="width:370px"
                            format="HH:mm"
                            confirm
                            :class="{
                                'is-invalid': errors.has('time_to')
                            }"
                        ></time-picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('time_to')"
                        >
                            {{ errors.first('time_to') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.otDate')
                    }}</label>
                    <div>
                        <date-Picker
                            v-model="model.ot_date"
                            type="date"
                            placeholder="Select Date"
                            style="width:370px"
                            @on-change="cycleDateChange"
                            format="dd-MM-yyyy"
                            :class="{
                                'is-invalid': errors.has('ot_date')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('ot_date')"
                        >
                            {{ errors.first('ot_date') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.break')
                    }}</label>
                    <div>
                        <input
                            v-model="model.break"
                            type="number"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('break')
                            }"
                            placeholder="Overs Time"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('break')"
                        >
                            {{ errors.first('break') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.year')
                    }}</label>
                    <div>
                        <date-Picker
                            v-model="model.cycle_year"
                            type="year"
                            placeholder="Select Date"
                            style="width:370px"
                            format="yyyy"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyOvertime.month')
                    }}</label>
                    <div>
                        <date-Picker
                            v-model="model.cycle_month"
                            type="month"
                            placeholder="Select Date"
                            style="width:370px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-label form-label tw-text-xs">{{
                    $t('monthlyOvertime.remark')
                }}</label>
                <div>
                    <Input
                        v-model="model.remarks"
                        type="textarea"
                        :rows="5"
                        placeholder="Enter something..."
                    />
                </div>
            </div>

            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        class="btn-gray"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
import { mapActions } from 'vuex'

export default {
    name: 'monthly-over-time-create',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                employee_id: null,
                cycle_year: null,
                cycle_month: null,
                ot_date: null,
                time_from: null,
                time_to: null,
                break: null,
                remarks: null
            }
        }
    },
    computed: {
        employeeProfiles () {
            return this.$store.state.payroll.monthlyOvertime.employeeProfiles
        }
    },
    methods: {
        ...mapActions('payroll/monthlyOvertime', ['getEmployeeProfile']),
        fetchResource () {
            this.loading = true
            this.getEmployeeProfile()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),
        cycleYearChange (value) {
            this.model.cycle_year = value
        },
        cycleMonthChange (value) {
            this.model.cycle_month = value
        },
        cycleDateChange (value) {
            this.model.ot_date = value
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/monthlyOvertime/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('ceacel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/monthlyOvertime/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.employee_id = null
            this.model.cycle_year = null
            this.model.cycle_month = null
            this.model.ot_date = null
            this.model.time_from = null
            this.model.time_to = null
            this.model.break = null
            this.model.remarks = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY OVERTIME',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
